const COLORS = {
  lnerRed: '#CE0E2D',
  white: '#FFFFFF',
  mercury: '#E7E7E7',
  doveGray: '#707070',
  wildSand: '#F5F5F5',
  dune: '#382F2D',
  forestGreen: '#258825'
};

export default COLORS;
