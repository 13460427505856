import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            baseUrl
            apiBaseUrl
            googleTagManagerId
          }
        }
      }
    `
  );
  return {
    baseUrl: site.siteMetadata.baseUrl,
    apiBaseUrl: site.siteMetadata.apiBaseUrl,
    googleTagManagerId: site.siteMetadata.googleTagManagerId
  };
};
