import React from 'react';
import COLORS from '../styles/colors';
import LnerLogo from '../icons/header-lner-logo';
import MQ from '../styles/breakpoints';
import { useBaseUrl } from '../helpers/url-hooks';

const Header: React.FC = () => {
  const url = useBaseUrl();
  return (
    <header
      css={{
        background: COLORS.white,
        marginBottom: 0
      }}
    >
      <div
        css={MQ({
          margin: `0 auto`,
          maxWidth: 506,
          padding: [`16px 1rem 12px`, `6px 1rem 2px`]
        })}
      >
        <a id="lnerLogo" href={url}>
          <LnerLogo />
        </a>
      </div>
    </header>
  );
};

export default Header;
